var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('modal',{ref:"modalCrearCedis",attrs:{"titulo":"Crear Cedis","tamano":"modal-lg","icon":"cedis"},on:{"guardar":_vm.crear_cedis}},[_c('ValidationObserver',{ref:"validacion"},[_c('div',{staticClass:"row mx-0 justify-center mb-3"},[_c('slim-cropper',{ref:"foto_cedis",staticClass:"border",staticStyle:{"height":"150px","background":"#F8F9FF","width":"150px","border-radius":"12px"},attrs:{"options":_vm.slimOptions}})],1),_c('div',{staticClass:"row mx-0 mt-4"},[_c('div',{staticClass:"col-6 px-2"},[_c('ValidationProvider',{attrs:{"rules":"required|max:30","name":"nombre cedis"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"pl-3 text-general f-14"},[_vm._v(" Nombre del Cedis ")]),_c('el-input',{staticClass:"w-100",attrs:{"size":"small","maxlength":"30","show-word-limit":"","placeholder":"Nombre"},model:{value:(_vm.model.nombre),callback:function ($$v) {_vm.$set(_vm.model, "nombre", $$v)},expression:"model.nombre"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col px-2"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"moneda"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"pl-3 text-general f-14"},[_vm._v(" Moneda ")]),_c('el-select',{staticClass:"w-100",attrs:{"size":"small","placeholder":"Moneda"},model:{value:(_vm.model.idm_moneda),callback:function ($$v) {_vm.$set(_vm.model, "idm_moneda", $$v)},expression:"model.idm_moneda"}},_vm._l((_vm.monedas),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nombre,"value":item.id}})}),1),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)]),_c('div',{staticClass:"row mx-0 mt-2"},[_c('div',{staticClass:"col-6 px-2"},[_c('div',{staticClass:"col-10 d-middle my-3"},[_c('ValidationProvider',{attrs:{"vid":"inventario","rules":""}},[_c('el-checkbox',{staticClass:"check-red",model:{value:(_vm.model.cedis_inventario),callback:function ($$v) {_vm.$set(_vm.model, "cedis_inventario", $$v)},expression:"model.cedis_inventario"}},[_vm._v(" Almacenar inventario en CEDIS diferente ")])],1)],1)]),(_vm.model.cedis_inventario)?_c('div',{staticClass:"col px-2"},[_c('ValidationProvider',{attrs:{"rules":"required_if:inventario,true","name":"cedis"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"pl-3 text-muted2"},[_vm._v(" Cedis ")]),_c('el-select',{staticClass:"w-100",attrs:{"size":"small","placeholder":"Cedis"},model:{value:(_vm.model.id_cedis_stock),callback:function ($$v) {_vm.$set(_vm.model, "id_cedis_stock", $$v)},expression:"model.id_cedis_stock"}},_vm._l((_vm.cedis),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nombre,"value":item.id}})}),1),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,2662958356)})],1):_vm._e(),_c('div',{staticClass:"col-6 px-2"},[_c('div',{staticClass:"col-10 d-middle my-3"},[_c('ValidationProvider',{attrs:{"vid":"catalogo","rules":""}},[_c('el-checkbox',{staticClass:"check-red",model:{value:(_vm.model.cedis_catalogo),callback:function ($$v) {_vm.$set(_vm.model, "cedis_catalogo", $$v)},expression:"model.cedis_catalogo"}},[_vm._v(" Usar catálogo de CEDIS diferente ")])],1)],1)]),(_vm.model.cedis_catalogo)?_c('div',{staticClass:"col px-2"},[_c('ValidationProvider',{attrs:{"rules":"required_if:catalogo,true","name":"cedis"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"pl-3 text-muted2"},[_vm._v(" Cedis ")]),_c('el-select',{staticClass:"w-100",attrs:{"size":"small","placeholder":"Cedis"},model:{value:(_vm.model.id_cedis_catalogo),callback:function ($$v) {_vm.$set(_vm.model, "id_cedis_catalogo", $$v)},expression:"model.id_cedis_catalogo"}},_vm._l((_vm.cedis),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nombre,"value":item.id}})}),1),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,3251834920)})],1):_vm._e()]),_c('div',{staticClass:"row mx-0 mt-2"},[_c('div',{staticClass:"col-6 px-2"},[_c('p',{staticClass:"ml-2 text-general f-14"},[_vm._v("Impuestos")]),_c('el-select',{staticClass:"w-100",attrs:{"size":"small"},model:{value:(_vm.model.impuestos),callback:function ($$v) {_vm.$set(_vm.model, "impuestos", $$v)},expression:"model.impuestos"}},[_c('el-option',{attrs:{"label":"Sin Impuestos","value":0}}),_c('el-option',{attrs:{"label":"Valores +IVA","value":1}}),_c('el-option',{attrs:{"label":"IVA incluido","value":2}}),_c('el-option',{attrs:{"label":"IVA incluido sin etiqueta","value":3}})],1)],1),_c('div',{staticClass:"col-6 px-2"},[_c('div',{staticClass:"col-10 d-middle my-3"},[_c('el-checkbox',{staticClass:"check-red",model:{value:(_vm.model.estado),callback:function ($$v) {_vm.$set(_vm.model, "estado", $$v)},expression:"model.estado"}},[_vm._v(" Activar Cedis ")])],1)])]),_c('div',{staticClass:"row mx-0"},[_c('ValidationProvider',{staticClass:"w-100",attrs:{"rules":"required|max:500","name":"descripción"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"text-general pl-3 f-14"},[_vm._v(" Descripción ")]),_c('el-input',{staticClass:"w-100",attrs:{"type":"textarea","rows":4,"placerholder":"Descripción","maxlength":"500","show-word-limit":""},model:{value:(_vm.model.descripcion),callback:function ($$v) {_vm.$set(_vm.model, "descripcion", $$v)},expression:"model.descripcion"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col px-2 mt-2"},[_c('ValidationProvider',{attrs:{"rules":"required|max:20","name":"teléfono"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"pl-3 text-general f-14"},[_vm._v(" Teléfono ")]),_c('el-input',{staticClass:"w-100",attrs:{"size":"small","maxlength":"20","show-word-limit":"","placeholder":"Teléfono"},on:{"change":_vm.buscarDireccion},model:{value:(_vm.model.telefono),callback:function ($$v) {_vm.$set(_vm.model, "telefono", $$v)},expression:"model.telefono"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-6 px-2 mt-2"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"responsable"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"pl-3 text-general f-14"},[_vm._v(" Responsable ")]),_c('el-select',{staticClass:"w-100",attrs:{"size":"small","placeholder":"Responsable"},model:{value:(_vm.model.id_user),callback:function ($$v) {_vm.$set(_vm.model, "id_user", $$v)},expression:"model.id_user"}},_vm._l((_vm.resposanbles),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nombre,"value":item.id}})}),1),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)]),_c('div',{staticClass:"row mx-0 mt-2"},[_c('div',{staticClass:"col-4 px-1"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"país"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"ml-2 text-general f-14"},[_vm._v("País")]),_c('el-select',{staticClass:"w-100",attrs:{"size":"small","filterable":""},on:{"change":_vm.consultarEstados},model:{value:(_vm.model.idm_pais),callback:function ($$v) {_vm.$set(_vm.model, "idm_pais", $$v)},expression:"model.idm_pais"}},_vm._l((_vm.paises),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nombre,"value":item.id}})}),1),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-4 px-2"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"estado"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"ml-2 text-general f-14"},[_vm._v("Estado")]),_c('el-select',{staticClass:"w-100",attrs:{"size":"small","filterable":""},on:{"change":_vm.consultarCiudades},model:{value:(_vm.model.idm_estado),callback:function ($$v) {_vm.$set(_vm.model, "idm_estado", $$v)},expression:"model.idm_estado"}},_vm._l((_vm.estados),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nombre,"value":item.id}})}),1),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-4 px-1"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"ciudad"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"ml-2 text-general f-14"},[_vm._v("Ciudad")]),_c('div',{staticClass:"row mx-0 align-items-center"},[_c('el-select',{staticClass:"col px-0",attrs:{"size":"small","filterable":""},model:{value:(_vm.model.idm_ciudad),callback:function ($$v) {_vm.$set(_vm.model, "idm_ciudad", $$v)},expression:"model.idm_ciudad"}},_vm._l((_vm.ciudades),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nombre,"value":item.id}})}),1)],1),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)]),_c('div',{staticClass:"row mx-0 my-2"}),_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-auto f-18 text-general mr-2"},[_vm._v(" Días de trabajo ")]),_vm._l((_vm.model.dias),function(dia,d){return _c('div',{key:(dia + "-" + d),class:("dia cr-pointer d-middle-center mx-2 " + (dia ? 'activo' : '')),on:{"click":function($event){return _vm.seleccionaDia(d)}}},[_vm._v(" "+_vm._s(_vm._f("ucfirst")(_vm.diaSemana(d)))+" ")])})],2),_c('div',{staticClass:"row mx-0 my-3"},[_c('ValidationProvider',{staticClass:"col px-2",attrs:{"rules":"required|max:120","tag":"div","name":"dirección"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"pl-3 text-general f-14"},[_vm._v(" Dirección ")]),_c('el-input',{staticClass:"w-100",attrs:{"size":"small","maxlength":"120","show-word-limit":"","placeholder":"Dirección"},on:{"change":_vm.buscarDireccion},model:{value:(_vm.model.direccion),callback:function ($$v) {_vm.$set(_vm.model, "direccion", $$v)},expression:"model.direccion"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"row mx-0 my-3 px-3"},[_c('div',{staticClass:"col 12 px-0"},[_c('p',{staticClass:"text-general f-15 text-center mt-2 mb-4"},[_vm._v(" Seleccione en el mapa la ubicacion exacta del Cedis ")]),_c('mapa',{ref:"mapaPeq",attrs:{"coordenadas":_vm.coordenadas,"icono":"cedis-point-map.svg","buscar":_vm.getDireccionCon},on:{"actualizar":_vm.actualizarCoordenadas}}),_c('p',{staticClass:"f-13 mt-2 text-gris2 cr-pointer",on:{"click":function($event){return _vm.abrirModalMapa()}}},[_vm._v(" Haga click aquí, para seleccionar la ubicación de su tienda ")])],1)])])],1),_c('mapa-google-modal',{ref:"modalMapaGoogle",attrs:{"busqueda":"","postal":"","icono-central":"/img/icons/cedis-point-map.svg","buscar":_vm.getDireccionCon,"coordenadas-entrada":_vm.coordenadas},on:{"actualizar":_vm.actualizarCoordenadasCiudad}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }