<template>
    <section>
        <modal ref="modalCrearCedis" titulo="Crear Cedis" tamano="modal-lg" icon="cedis" @guardar="crear_cedis">
            <ValidationObserver ref="validacion">
                <div class="row mx-0 justify-center mb-3">
                    <slim-cropper ref="foto_cedis" :options="slimOptions" class="border" style="height:150px;background:#F8F9FF;width:150px;border-radius:12px;" />
                </div>
                <div class="row mx-0 mt-4">
                    <div class="col-6 px-2">
                        <ValidationProvider v-slot="{errors}" rules="required|max:30" name="nombre cedis">
                            <label class="pl-3 text-general f-14"> Nombre del Cedis </label>
                            <el-input v-model="model.nombre" class="w-100" size="small" maxlength="30" show-word-limit placeholder="Nombre" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col px-2">
                        <ValidationProvider v-slot="{errors}" rules="required" name="moneda">
                            <label class="pl-3 text-general f-14"> Moneda </label>
                            <el-select v-model="model.idm_moneda" size="small" placeholder="Moneda" class="w-100">
                                <el-option
                                v-for="item in monedas"
                                :key="item.id"
                                :label="item.nombre"
                                :value="item.id"
                                />
                            </el-select>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 mt-2">
                    <div class="col-6 px-2">
                        <div class="col-10 d-middle my-3">
                            <ValidationProvider vid="inventario" rules="">
                                <el-checkbox v-model="model.cedis_inventario" class="check-red">
                                    Almacenar inventario en CEDIS diferente
                                </el-checkbox>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div v-if="model.cedis_inventario" class="col px-2">
                        <ValidationProvider v-slot="{errors}" rules="required_if:inventario,true" name="cedis">
                            <label class="pl-3 text-muted2"> Cedis </label>
                            <el-select v-model="model.id_cedis_stock" size="small" placeholder="Cedis" class="w-100">
                                <el-option
                                v-for="item in cedis"
                                :key="item.id"
                                :label="item.nombre"
                                :value="item.id"
                                />
                            </el-select>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-6 px-2">
                        <div class="col-10 d-middle my-3">
                            <ValidationProvider vid="catalogo" rules="">
                                <el-checkbox v-model="model.cedis_catalogo" class="check-red">
                                    Usar catálogo de CEDIS diferente
                                </el-checkbox>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div v-if="model.cedis_catalogo" class="col px-2">
                        <ValidationProvider v-slot="{errors}" rules="required_if:catalogo,true" name="cedis">
                            <label class="pl-3 text-muted2"> Cedis </label>
                            <el-select v-model="model.id_cedis_catalogo" size="small" placeholder="Cedis" class="w-100">
                                <el-option
                                v-for="item in cedis"
                                :key="item.id"
                                :label="item.nombre"
                                :value="item.id"
                                />
                            </el-select>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 mt-2">
                    <div class="col-6 px-2">
                        <p class="ml-2 text-general f-14">Impuestos</p>
                        <el-select v-model="model.impuestos" size="small" class="w-100">
                            <el-option label="Sin Impuestos" :value="0" />
                            <el-option label="Valores +IVA" :value="1" />
                            <el-option label="IVA incluido" :value="2" />
                            <el-option label="IVA incluido sin etiqueta" :value="3" />
                        </el-select>
                    </div>
                    <div class="col-6 px-2">
                        <div class="col-10 d-middle my-3">
                            <el-checkbox v-model="model.estado" class="check-red">
                                Activar Cedis
                            </el-checkbox>
                        </div>
                    </div>
                </div>
                <div class="row mx-0">
                    <ValidationProvider v-slot="{errors}" class="w-100" rules="required|max:500" name="descripción">
                        <label class="text-general pl-3 f-14"> Descripción </label>
                        <el-input v-model="model.descripcion" type="textarea" class="w-100" :rows="4" placerholder="Descripción" maxlength="500" show-word-limit />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="row mx-0">
                    <div class="col px-2 mt-2">
                        <ValidationProvider v-slot="{errors}" rules="required|max:20" name="teléfono">
                            <label class="pl-3 text-general f-14"> Teléfono </label>
                            <el-input v-model="model.telefono" size="small" class="w-100" maxlength="20" show-word-limit placeholder="Teléfono" @change="buscarDireccion" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-6 px-2 mt-2">
                        <ValidationProvider v-slot="{errors}" rules="required" name="responsable">
                            <label class="pl-3 text-general f-14"> Responsable </label>
                            <el-select v-model="model.id_user" size="small" placeholder="Responsable" class="w-100">
                                <el-option
                                v-for="item in resposanbles"
                                :key="item.id"
                                :label="item.nombre"
                                :value="item.id"
                                />
                            </el-select>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 mt-2">
                    <div class="col-4 px-1">
                        <ValidationProvider v-slot="{errors}" rules="required" name="país">
                            <p class="ml-2 text-general f-14">País</p>
                            <el-select v-model="model.idm_pais" size="small" class="w-100" filterable @change="consultarEstados">
                                <el-option
                                v-for="item in paises"
                                :key="item.id"
                                :label="item.nombre"
                                :value="item.id"
                                />
                            </el-select>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-4 px-2">
                        <ValidationProvider v-slot="{errors}" rules="required" name="estado">
                            <p class="ml-2 text-general f-14">Estado</p>
                            <el-select v-model="model.idm_estado" size="small" class="w-100" filterable @change="consultarCiudades">
                                <el-option
                                v-for="item in estados"
                                :key="item.id"
                                :label="item.nombre"
                                :value="item.id"
                                />
                            </el-select>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-4 px-1">
                        <ValidationProvider v-slot="{errors}" rules="required" name="ciudad">
                            <p class="ml-2 text-general f-14">Ciudad</p>
                            <div class="row mx-0 align-items-center">
                                <el-select v-model="model.idm_ciudad" size="small" class="col px-0" filterable>
                                    <el-option
                                    v-for="item in ciudades"
                                    :key="item.id"
                                    :label="item.nombre"
                                    :value="item.id"
                                    />
                                </el-select>
                                <!-- <i class="icon-pencil f-18 cr-pointer" @click="disabled = !disabled" /> -->
                            </div>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 my-2" />

                <div class="row mx-0">
                    <div class="col-auto f-18 text-general mr-2">
                        Días de trabajo
                    </div>
                    <div v-for="(dia, d) in model.dias" :key="`${dia}-${d}`" :class="`dia cr-pointer d-middle-center mx-2 ${dia ? 'activo' : ''}`" @click="seleccionaDia(d)">
                        {{ diaSemana(d) | ucfirst }}
                    </div>
                </div>
                <div class="row mx-0 my-3">
                    <ValidationProvider v-slot="{errors}" rules="required|max:120" tag="div" class="col px-2" name="dirección">
                        <label class="pl-3 text-general f-14"> Dirección </label>
                        <el-input v-model="model.direccion" size="small" class="w-100" maxlength="120" show-word-limit placeholder="Dirección" @change="buscarDireccion" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="row mx-0 my-3 px-3">
                    <div class="col 12 px-0">
                        <p class="text-general f-15 text-center mt-2 mb-4">
                            Seleccione en el mapa la ubicacion exacta del Cedis
                        </p>
                        <mapa ref="mapaPeq" :coordenadas="coordenadas" icono="cedis-point-map.svg" :buscar="getDireccionCon" @actualizar="actualizarCoordenadas" />
                        <p class="f-13 mt-2 text-gris2 cr-pointer" @click="abrirModalMapa()">
                            Haga click aquí, para seleccionar la ubicación de su tienda
                        </p>
                    </div>
                </div>
            </ValidationObserver>
        </modal>
        <mapa-google-modal
        ref="modalMapaGoogle"
        busqueda
        postal
        icono-central="/img/icons/cedis-point-map.svg"
        :buscar="getDireccionCon"
        :coordenadas-entrada="coordenadas"
        @actualizar="actualizarCoordenadasCiudad"
        />
    </section>
</template>
<script>
import { mapGetters } from 'vuex'
import Localizaciones from "~/services/proyectos/localizaciones";
import Cedis from "../../../services/cedis/index"
import CedisAll from "~/services/cedis";

export default {
    data(){
        return{
            disabled: true,
            paises: [],
            estados: [],
            ciudades: [],
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            model: {
                estado: true,
                cedis_inventario: false,
                cedis_catalogo: false,
                nombre: '',
                direccion: '',
                impuestos:0,
                telefono: '',
                idm_moneda: null,
                descripcion: '',
                latitud: '',
                longitud: '',
                id_user: null,
                idm_pais: null,
                idm_estado: null,
                id_cedis_stock: null,
                id_cedis_catalogo: null,
                idm_ciudad: null,
                dias: [0,0,0,0,0,0,0]
            },
            coordenadas: {
                lat: 7.080734,
                lng: -73.147827,
            },
            resposanbles: [],
            cedis: [],
        }
    },
    computed: {
        ...mapGetters({
            monedas:'cedis/monedas',
        }),
        getDireccionCon(){
            const { ciudad = '' } = this.ciudades.find(x => x.id === this.model.idm_ciudad) || {};
            const { nombre: pais = '' } = this.paises.find(x => x.id === this.model.idm_pais) || {};
            return [this.model.direccion, ciudad, pais].filter(el => el).join(',')
        },
    },
    methods: {
        toggle(){
            this.get_cedis()
            this.get_responsables()
            this.consultarPaises()
            this.limpiar()
            this.$refs.mapaPeq.setPosition()
            this.$refs.modalCrearCedis.toggle();
        },
        abrirModalMapa(){
            this.$refs.modalMapaGoogle.toggle()
        },
        actualizarCoordenadasCiudad({ latitud, longitud }){
            this.model.latitud = latitud
            this.model.longitud = longitud
            this.coordenadas.lat = +this.model.latitud
            this.coordenadas.lng = +this.model.longitud
            this.$refs.mapaPeq.setPosition()
        },
        async crear_cedis(){
            try {

                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                const archivo = this.$refs.foto_cedis.instanciaCrop.dataBase64.output
                this.model.foto = archivo.image

                const {data} = await Cedis.crear_cedis(this.model)
                this.notificacion('Mensaje', 'Centro de distribución creado exitosamente', 'success')
                this.$emit('listar')
                this.limpiar()
                this.$store.dispatch('cedis/action_get_cedis')
                this.$refs.modalCrearCedis.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        async get_responsables(){
            try {
                const {data} = await Cedis.get_responsables()
                this.resposanbles = data
            } catch (e){
                this.error_catch(e)
            }
        },
        async get_cedis(){
            try {
                const {data} = await CedisAll.get_cedis()
                this.cedis = data.cedis
            } catch (e){
                this.error_catch(e)
            }
        },
        limpiar(){
            this.model = {
                estado: true,
                cedis_inventario: false,
                cedis_catalogo: false,
                nombre: '',
                direccion: '',
                impuestos:0,
                telefono: '',
                idm_moneda: null,
                descripcion: '',
                latitud: 7.080734,
                longitud:-73.147827,
                id_user: null,
                idm_pais: null,
                idm_estado: null,
                id_cedis_stock: null,
                id_cedis_catalogo: null,
                idm_ciudad: null,
                dias: [0,0,0,0,0,0,0]
            }
            this.$refs.foto_cedis.instanciaCrop.remove()
        },
        async consultarPaises(){
            try {
                const {data} = await Localizaciones.consultarPaises(this.$usuario.proyecto.id)
                this.paises = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async consultarEstados(){
            try {
                this.model.idm_estado = null
                this.model.idm_ciudad = null
                this.estados = []
                this.ciudades = []
                const {data} = await Localizaciones.consultarEstados({
                    proyecto: this.$usuario.proyecto.id,
                    pais: this.model.idm_pais
                })

                this.estados = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async consultarCiudades(){
            try {
                this.model.idm_ciudad = null
                this.ciudades = []
                const {data} = await Localizaciones.consultarCiudades({
                    proyecto: this.$usuario.proyecto.id,
                    estado: this.model.idm_estado
                })
                this.model.idm_ciudad = null
                this.ciudades = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        actualizarCoordenadas({ latitud, longitud }){
            this.model.latitud = latitud
            this.model.longitud = longitud
        },
        buscarDireccion(){
            this.$refs.mapaPeq.accionBuscar();
        },
        seleccionaDia(dia){
            let newValue = +(!this.model.dias[dia])
            this.$set(this.model.dias, dia, newValue)
        },
    }
}
</script>

<style lang="scss" scoped>

.dia{
    width: 93px;
    height: 32px;
    border: 1px solid #DFE4E8;
    border-radius: 8px;
    &.activo{
        background-color: var(--text-general) !important;
        color: #ffffff !important;
        border: none !important;
    }
}
</style>
